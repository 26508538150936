import React, { Component } from 'react';
import StyledFullCalendar from './StyledFullCalendar';

import FullCalendar, { EventInput } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import bootstrapPlugin from '@fullcalendar/bootstrap';

import ReactDOM from 'react-dom';

// @ts-ignore
import esLocale from '@fullcalendar/core/locales/es';
import enLocale from '@fullcalendar/core/locales/en-gb';
import ptLocale from '@fullcalendar/core/locales/pt-br';

import './Calendar.scss';
import moment from 'moment';

import { CalendarEventRender } from './CalendarEventRender';
import TimeTaxSupportSystemClient from '../../system';
import { CalendarEventChangeDateDialog } from './CalendarEventChangeDateDialog';
import { IEventDataChangeDate, IHorasEsperadasPanel } from '../../system/activity/ActivitySystem';
import TTSEvent from './TTSEvent';
import MessageCourier from '../../lib/MessageCourier';
import { DateCustom } from '../../lib/DateCustom';
import { IProjectDescriptor } from '../../system/projects/ProjectDescriptor';
import { User } from '../../system/User';
import i18next, { t } from 'i18next';
import { EN_LANGUAGE_VALUE, ES_LANGUAGE_VALUE, getSessionStorageLanguage } from '../../i18n';
import SystemConfiguration from '../../system/configuration/SystemConfiguration';
import PeriodDatesBlockedSystem from '../../system/period_dates_blocked/PeriodDatesBlockedSystem';

import { Callout, Icon } from 'office-ui-fabric-react';
import { Text } from '@fluentui/react';
import {
  CalendarHorasEsperadasProgressBar,
  ICalendarHorasEsperadasProgressBar,
} from './CalendarHorasEsperadasProgressBar';
import { DateTime } from 'luxon';
import { ICalendarConfig, LAST_NAVIGATION, LAST_VIEW, MODE_24HS, saveOnLocalStore } from './CalendarMenu';
import { IActivityCounts } from './CalendarView';

// import * as proyecto from '../../common/entities/proyecto';
// import { eventNewDiv } from './Calendar.events';
// import { ReloadIcon } from '../Icons/ReloadIcon';

// import { calendarMaxTime, calendarMinTime } from '../../common/constants';

interface CalendarEventExtendedProps<T> {
  data: T;
  onDuplicate?: () => void;
  onReplicate?: () => void;
  onDelete?: () => void;
  onReactivar?: () => void;
  onEdit?: () => void;
}

export interface CalendarEvent<T = any> extends Omit<EventInput, 'start' | 'end'> {
  extendedProps: CalendarEventExtendedProps<T>;
  start: string | Date;
  end: string | Date;
}

export interface CalendarFilters {
  proyectoId: string;
  verDeclinadas: boolean;
  verEliminadas: boolean;
  verOcultas: boolean;
  verEventosOutlook: boolean;
}
// calendarEvents: EventSourceInput[];
export interface CalendarProps {
  system?: TimeTaxSupportSystemClient;
  user: User;
  calendarEvents: EventInput[];
  projects: IProjectDescriptor[];
  onTimeIntervalChange?: (start: Date, end: Date) => unknown;
  onEventClick?: (event: CalendarEvent) => unknown;
  onEventResize?: (event: CalendarEvent) => unknown;
  onEventDrop?: (event: CalendarEvent) => boolean;
  onSaveEventDrop?: (arg: any) => unknown;
  onNewEventClick?: (event: CalendarEvent) => unknown;
  filtros?: CalendarFilters;
  onProyectoChange?: (id: string) => Promise<void>;
  onCleanFilters?: () => Promise<void>;
  // proyectos?: proyecto.Proyecto[];
  onVerDeclinadasChange?: (verDeclinadas?: boolean) => Promise<void>;
  onVerEliminadasChange?: (verEliminadas?: boolean) => Promise<void>;
  onVerOcultasChange?: (verOcultas?: boolean) => Promise<void>;
  onVerEventosOutlook?: (verEventosOutlook?: boolean) => Promise<void>;
  revertEvent?: boolean;
  goTo?: Date;
  eventToRevert?: any;
  habilitar24hs?: boolean;
  showWeekends?: boolean;
  commonContext?: any;
  getEvents?: () => void;
  courier: MessageCourier;
  // t: TFunction;
  startCalendar?: string;
  changeBlockUI?: (state: boolean) => void;
  activityIdFromNotif?: string | null;
  // openUnableModifyHoursDialog?: () => void;
  config: SystemConfiguration;
  cleanNotifParam: (v: boolean) => void;
  horasDataPanel?: ICalendarHorasEsperadasProgressBar;
  activeStart?: Date;
  activeEnd?: Date;
  periodsBlocked: PeriodDatesBlockedSystem;
  ActivityCounts: IActivityCounts
}

export interface ExternalEvent {
  color: string;
  name: string;
}

const UMBRAL = 0.75;
type ActividadEvent = CalendarEvent<{ id: string }>;

interface CalendarState {
  selectedEvent: null | ActividadEvent;
  evRemoveOnDrop: boolean;
  evSelectedColor: string;
  evNewName: string;
  externalEvents: ExternalEvent[];
  open: boolean;
  selectedDate: Date;
  showChangeDateDialog: boolean;
  eventToChangeDate?: IEventDataChangeDate;
  activityIdFromNotif: string | null;
  eventWasClosed: boolean;
  isCalloutVisible: boolean;
}

class CalendarProjectView extends Component<CalendarProps, CalendarState> {
  calendarPlugins = [interactionPlugin, dayGridPlugin, timeGridPlugin, listPlugin, bootstrapPlugin];

  calendarHeader = {
    left: 'prev,next',
    center: 'today,title,hoursMiniPanel,dayGridMonth,timeGridWeek,timeGridDay,listWeek',
    right: '',
  };

  // External events properties

  evColors = ['danger', 'primary', 'info', 'success', 'warning', 'green', 'pink', 'inverse', 'purple'];

  calendarRef = React.createRef<FullCalendar>();

  FIRST_HOUR: number = 8;

  constructor(props: CalendarProps) {
    super(props);

    const queryParams = new URLSearchParams(window.location.search);
    const activityIdFromNotif = queryParams.get('Actividad');

    this.state = {
      selectedEvent: null,
      evRemoveOnDrop: false,
      evSelectedColor: this.evColors[0],
      evNewName: '',
      externalEvents: [{ color: '', name: '' }],
      open: false,
      selectedDate: new Date(),
      showChangeDateDialog: false,
      eventToChangeDate: undefined,
      activityIdFromNotif: activityIdFromNotif,
      eventWasClosed: false,
      isCalloutVisible: false,
    };
  }

  componentDidMount() {
    if (this.calendarRef && this.calendarRef.current) {
      let calendarApi = this.calendarRef.current.getApi();
      if (calendarApi) {
        calendarApi.scrollToTime('9:00');
      }
    }
  }

  componentDidUpdate(props: CalendarProps, state: CalendarState) {
    if (props.goTo !== this.props.goTo && this.props.goTo) {
      this.goToDate(this.props.goTo);
    }
    if (this.calendarRef && this.calendarRef.current) {
      let calendarApi = this.calendarRef.current.getApi();
      if (calendarApi) {
        // calendarApi.scrollToTime('9:00');
      }
    }
    if (props.revertEvent != this.props.revertEvent) {
      if (this.props.eventToRevert) {
        this.revert(this.props.eventToRevert);
      }
    }
    if (this.props.activityIdFromNotif && this.props.activityIdFromNotif !== this.state.activityIdFromNotif) {
      this.setState({ activityIdFromNotif: this.props.activityIdFromNotif, eventWasClosed: false });
    }
    // this.setState({activityIdFromNotif: activityIdFromNotif})
  }

  static getNewActivity(date: Date): ActividadEvent {
    const actividadNueva = {} as ActividadEvent;

    actividadNueva.title = t('Nueva actividad');
    actividadNueva.start = date;
    actividadNueva.end = this.getDefaultDuration(date);
    actividadNueva.allDay = false;
    actividadNueva.backgroundColor = '#ff5555';
    actividadNueva.borderColor = '#ff5555';
    actividadNueva.extendedProps = { data: { id: 'new' } };

    return actividadNueva;
  }

  static getDefaultDuration(date: Date): Date {
    const defMinDuration: number = 30;
    let datex = moment(date);
    return datex.add(defMinDuration, 'minutes').toDate();
  }

  // add event directly into calendar
  addEvent(event: any) {
    // this.props.calendarEvents.push(event);
    const newEvents: CalendarProps = {
      calendarEvents: this.props.calendarEvents.concat(event),
      projects: this.props.projects,
      courier: this.props.courier,
      user: this.props.user,
      config: this.props.config,
      periodsBlocked: this.props.periodsBlocked,
      cleanNotifParam: this.props.cleanNotifParam,
      ActivityCounts: this.props.ActivityCounts
    };

    return newEvents;
  }

  handleEventReceive = (info: any) => {
    var styles = getComputedStyle(info.draggedEl);
    info.event.setProp('backgroundColor', styles.backgroundColor);
    info.event.setProp('borderColor', styles.borderColor);

    // is the "remove after drop" checkbox checked?
    if (this.state.evRemoveOnDrop) {
      this.removeExternalEvent(info.draggedEl.textContent);
    }
  };

  getEvColorClasses(evcolor: string) {
    return 'bg-' + evcolor + (this.state.evSelectedColor === evcolor ? ' selected' : '');
  }

  addNewExternalEvent = () => {
    const externalEvents = this.state.externalEvents.concat({
      color: this.state.evSelectedColor,
      name: this.state.evNewName,
    });
    this.setState({
      externalEvents,
    });
  };

  removeExternalEvent = (name: any) => {
    let externalEvents = [...this.state.externalEvents];
    const index = externalEvents.findIndex((e) => e.name === name);
    if (index > -1) {
      externalEvents.splice(index, 1);
      this.setState({
        externalEvents,
      });
    }
  };

  selectColor = (color: string) => () => {
    this.setState({
      evSelectedColor: color,
    });
  };

  handleCheck = (event: any) => {
    this.setState({
      evRemoveOnDrop: event.target.checked,
    });
  };

  handleInputName = (event: any) => {
    this.setState({
      evNewName: event.target.value,
    });
  };

  // onDatesRender = ({ view }: { view: View; el: HTMLElement }) => {
  onDatesRender = (activeStart: Date, activeEnd: Date) => {
    if (this.props.onTimeIntervalChange) {
      this.props.onTimeIntervalChange(activeStart, activeEnd);
    }
    this.setState({ selectedDate: activeStart });
  };

  onChangeView = (view: string) => {
    saveOnLocalStore(LAST_VIEW, view);
  };

  onNewEventClick = (date: any) => {
    const actividadNueva = CalendarProjectView.getNewActivity(date.date);
    if (this.props.onNewEventClick) {
      this.props.onNewEventClick(actividadNueva);
    }
  };

  getLastViewFromLocalStore = (): string => {
    let calendarConfigStorage = localStorage.getItem('calendarConfig');
    let calendarConfig = {} as ICalendarConfig;
    if (calendarConfigStorage) {
      calendarConfig = JSON.parse(calendarConfigStorage);
      if (calendarConfig[LAST_VIEW] !== undefined) {
        return calendarConfig[LAST_VIEW];
      }
    }
    return 'timeGridWeek';
  };

  getLastNavigationFromLocalStore = (): Date | undefined => {
    let calendarConfigStorage = localStorage.getItem('calendarConfig');
    let calendarConfig = {} as ICalendarConfig;
    if (calendarConfigStorage) {
      calendarConfig = JSON.parse(calendarConfigStorage);
      if (calendarConfig[LAST_NAVIGATION] != undefined) {
        return new Date(calendarConfig[LAST_NAVIGATION]);
      }
    }
    return undefined;
  };

  onEventClick = (arg: any) => {
    const className = arg.jsEvent.target.parentNode.className;
    if (
        typeof className === 'string' &&
        !className.includes('menu-icon-tts') &&
        !className.includes('menu-item-tts') &&
        !className.includes('actividad-menu-tts-item') &&
        !className.includes('actividad-menu-tts')
    ) {
      if (this.props.onEventClick) {
        this.props.onEventClick(arg.event);
      }
    }
  };

  goToDate = (d: Date) => {
    if (this.calendarRef && this.calendarRef.current) {
      let calendarApi = this.calendarRef.current.getApi();
      if (calendarApi) {
        calendarApi.gotoDate(d);
      }
    }
  };

  onEventChangeDate = (event: ActividadEvent) => {
    let _tts = new TTSEvent();

    // Si es una actividad nueva de outlook avisar que primero deberia importar la actividad
    if (_tts.isNewEventFromOutlook(event)) {
      event.setStart(DateCustom.ParseDateFromApiStr(event._def.extendedProps.data.act_inicio));
      event.setEnd(DateCustom.ParseDateFromApiStr(event._def.extendedProps.data.act_fin));
      this.props.courier.messageError(
          t('Para modificar la fecha de la actividad de Outlook primero debes importarla')
      );
      return;
    }

    // Si es una actividad creada que pregunte si esta seguro de cambiar la fecha
    if (!_tts.isNewEvent(event)) {
      this.setState({
        showChangeDateDialog: true,
        eventToChangeDate: { id: event._def.extendedProps.data.id, start: event.start, end: event.end! },
      });
    }

    // Si es una actividad nueva que cambie la fecha directamente, no hacer nada =)
  };

  toggleIsCalloutVisible = () => {
    this.setState({ ...this.state, isCalloutVisible: !this.state.isCalloutVisible });
  };

  onCloseChangeDateDialog = async (accept: boolean) => {
    if (accept) {
      if (this.state.eventToChangeDate && this.props.system) {
        if (this.props.changeBlockUI) this.props.changeBlockUI(true);
        try {
          await this.props.system
              .getActivitySystem()
              .saveChangeDateFromCalendar(this.state.eventToChangeDate);
          this.props.courier.messageSuccess(t('La actividad se actualizó correctamente'));
        } catch (error) {
          this.props.courier.messageError(error);
        } finally {
          if (this.props.changeBlockUI) this.props.changeBlockUI(false);
        }
      }
    }
    if (this.props.getEvents) {
      this.props.getEvents();
    }
    this.setState({
      showChangeDateDialog: false,
      eventToChangeDate: undefined,
    });
  };

  handleEventResize = (arg: any) => {
    arg.jsEvent.preventDefault();
    this.onEventChangeDate(arg.event);
  };

  handleEventDrop = (arg: any) => {
    // arg.jsEvent.preventDefault();
    this.onEventChangeDate(arg.event);
  };

  revert = (arg: any) => {
    arg.revert();
  };

  onSelectedDateChange = (e: any) => {
    if (e instanceof Date) {
      this.setState({ selectedDate: e });
      this.goToDate(e);
    }
  };

  getStartMinTime = () => {
    if (this.props.habilitar24hs) {
      return '00:00';
    } else {
      if (this.props.startCalendar) {
        return this.props.startCalendar;
      } else {
        return '08:00';
      }
    }
  };

  onCloseEvent = (v: boolean) => {
    if (v) {
      this.setState({ eventWasClosed: v });
      // this.props.cleanNotifParam(v);
    }
  };

  isBelowInHours() {
    return (
        this.props.horasDataPanel!.horasCargadasPlantilla < this.props.horasDataPanel!.horasEsperadas * UMBRAL
    );
  }

  getActiveEndForPanel() {
    if (this.props.activeEnd) {
      let _end = new Date(this.props.activeEnd.getTime());
      _end.setDate(this.props.activeEnd.getDate() - 1);

      const datetimeEnd = DateTime.fromJSDate(new Date(this.props.activeEnd.getTime()));

      if (datetimeEnd.diffNow(['days']).days > 0) {
        _end = new Date();
        _end.setDate(_end.getDate() - 1);
      }
      return _end;
    }
    return new Date();
  }

  render() {
    // const { t } = this.props;
    var userLang = getSessionStorageLanguage();
    let preferredLanguage: any = undefined;

    if (userLang === ES_LANGUAGE_VALUE) {
      preferredLanguage = esLocale;
    }
    if (userLang === EN_LANGUAGE_VALUE) {
      preferredLanguage = enLocale;
    }

    return (
        <>
          <div className="calendar-app">
            {this.props.projects ? (
                <div>
                  <div className="ms-Grid  ">
                    <StyledFullCalendar>
                      <FullCalendar
                          customButtons={{
                            hoursMiniPanel: {
                              // @ts-ignore
                              text: (
                                  <div id="callout-hours-status" title="Estado de horas esperadas">
                                    <Icon iconName="Clock" className="text-icon-toolbar" />
                                    {this.isBelowInHours() ? (
                                        <Icon iconName="MarketDown" className="text-icon-toolbar cancel-color" />
                                    ) : (
                                        <Icon iconName="Market" className="text-icon-toolbar checkmark-color " />
                                    )}
                                  </div>
                              ),
                              title: 'Estado de horas esperadas',
                              click: () => {
                                this.toggleIsCalloutVisible();
                              },
                            },
                          }}
                          initialDate={this.getLastNavigationFromLocalStore()}
                          slotMinTime={this.getStartMinTime()}
                          slotMaxTime={'24:00'}
                          scrollTime={'09:00'}
                          locale={preferredLanguage}
                          plugins={this.calendarPlugins}
                          initialView={this.getLastViewFromLocalStore()}
                          events={this.props.calendarEvents}
                          // themeSystem={'bootstrap'}
                          headerToolbar={this.calendarHeader}
                          editable={true}
                          droppable={false}
                          weekends={this.props.showWeekends ? true : false}
                          timeZone={'local'}
                          allDaySlot={false}
                          contentHeight={'auto'}
                          aspectRatio={3.0}
                          dateClick={this.onNewEventClick}
                          eventReceive={this.handleEventReceive}
                          ref={this.calendarRef}
                          datesSet={(dateInfo: any) => {
                            const newEnd = new Date(dateInfo.end);
                            newEnd.setDate(newEnd.getDate() - 1);
                            if (dateInfo.view.type === 'dayGridMonth') {
                              const start = DateCustom.getCustomLastNavigationForMonthView(
                                  dateInfo.start,
                                  newEnd
                              );
                              saveOnLocalStore(LAST_NAVIGATION, start);
                              this.onDatesRender(dateInfo.start, newEnd);
                            } else {
                              saveOnLocalStore(LAST_NAVIGATION, dateInfo.start);
                              this.onDatesRender(dateInfo.start, newEnd);
                            }
                            this.onChangeView(dateInfo.view.type);
                          }}
                          eventClick={this.onEventClick}
                          eventResize={this.handleEventResize}
                          eventDurationEditable={true}
                          eventChange={this.handleEventDrop}
                          eventContent={(eventData) => {
                            if (
                                eventData &&
                                this.props.getEvents &&
                                this.props.courier &&
                                this.props.changeBlockUI &&
                                // this.props.openUnableModifyHoursDialog &&
                                this.props.config
                            ) {
                              return (
                                  <CalendarEventRender
                                      periodsBlocked={this.props.periodsBlocked}
                                      courier={this.props.courier}
                                      system={this.props.system}
                                      user={this.props.user}
                                      getEvents={this.props.getEvents}
                                      eventData={eventData}
                                      projects={this.props.projects}
                                      commonContext={this.props.commonContext}
                                      changeBlockUI={this.props.changeBlockUI}
                                      activityIdFromNotif={this.state.activityIdFromNotif}
                                      eventWasClosed={this.state.eventWasClosed}
                                      onCloseEvent={this.onCloseEvent}
                                      // openUnableModifyHoursDialog={this.props.openUnableModifyHoursDialog}
                                      config={this.props.config}
                                  />
                              );
                            }
                          }}
                      ></FullCalendar>
                    </StyledFullCalendar>
                    <div className="ms-Grid-row mt-2">
                      <div className="ms-Grid-col">
                        <h5 className="ms-Grid-row mt-11">
                        <span className="status-square status-yellow "></span>
                        {"Tienes  " + this.props.ActivityCounts.outlook + "  actividades no registradas en TTS provenientes de Calendar Corporativo"} 
                        </h5>
                        <h5 className="ms-Grid-row mt-11">
                        <span className="status-square status-red"></span>
                        {"Tienes  " + this.props.ActivityCounts.actDeclinadas + "  actividades etiquetadas TTS"}
                        </h5>
                        <h5 className="ms-Grid-row mt-11">
                        <span className="status-square status-gris"></span>
                        {"Tienes  " + this.props.ActivityCounts.eliminado + "  actividades eliminadas " }
                        </h5>
                        <h5 className="ms-Grid-row mt-11">
                        <span className="status-square status-blue"></span>
                        {"Tienes  " + this.props.ActivityCounts.actAceptadas + "  actividades registradas en TTS" }
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
            ) : null}
            <CalendarEventChangeDateDialog
                onClose={this.onCloseChangeDateDialog}
                show={this.state.showChangeDateDialog}
            ></CalendarEventChangeDateDialog>

            {this.state.isCalloutVisible && (
                <Callout
                    className="callout-panel-horas"
                    target={`#callout-hours-status`}
                    onDismiss={this.toggleIsCalloutVisible}
                    role="alert"
                    calloutWidth={450}
                    styles={{
                      calloutMain: {
                        overflowX: 'hidden !important',
                        overflowY: 'hidden !important',
                      },
                    }}
                >
                  <Text variant="mediumPlus" className=" text-center d-block fw-500">
                    {t('Minipanel de horas')}{' '}
                  </Text>
                  <Text variant="small" className=" text-center d-block fw-500 ms-fontSize-10">
                    ({this.props.horasDataPanel!.fechaDesde} - {this.props.horasDataPanel!.fechaHasta})
                  </Text>

                  {this.isBelowInHours() ? (
                      <Text variant="medium" className="d-block mt-1">
                        {t('Estás por debajo del')} {UMBRAL * 100}
                        {t('% de tus horas esperadas')}
                      </Text>
                  ) : (
                      <Text variant="medium" className="d-block mt-1">
                        {t('Estás por encima del')} {UMBRAL * 100}
                        {t('% de tus horas esperadas')}
                        {/* Horas cargadas: {this.props.horasDataPanel?.horasCargadas} hs. */}
                      </Text>
                  )}

                  <CalendarHorasEsperadasProgressBar
                      horasCargadasPlantilla={this.props.horasDataPanel!.horasCargadasPlantilla}
                      horasEsperadas={this.props.horasDataPanel!.horasEsperadas}
                      horasExcedidas={this.props.horasDataPanel!.horasExcedidas}
                      horasFaltantes={this.props.horasDataPanel!.horasFaltantes}
                      horasLicencia={this.props.horasDataPanel!.horasLicencia}
                      fechaDesde={this.props.horasDataPanel!.fechaDesde}
                      fechaHasta={this.props.horasDataPanel!.fechaHasta}
                      user={this.props.user}
                  />
                </Callout>
            )}
          </div>
        </>
    );
  }
}

const handleEventRemoved = ({ el }: { el: HTMLElement }) => {
  ReactDOM.unmountComponentAtNode(el);
};

export default CalendarProjectView;
